import React from "react"
import { graphql } from "gatsby"

import PostTemplate from "src/templates/PostTemplate.js"

const BlogPost = ({ data: { blogPost, bottom_cta } }) => {
  return <PostTemplate bottom_cta={bottom_cta} post={blogPost} type="blog" />
}

export default BlogPost

export const query = graphql`
  query PostSlug($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
    # blogPosts: allContentfulBlogPost(
    #   filter: { slug: { ne: $slug } }
    #   sort: { order: DESC, fields: date }
    #   limit: 3
    # ) {
    #   edges {
    #     node {
    #       ...BlogPost
    #     }
    #   }
    # }
    # extraBlogPosts: allContentfulBlogPost(
    #   filter: { slug: { ne: $slug } }
    #   sort: { order: DESC, fields: date }
    #   limit: 3
    # ) {
    #   edges {
    #     node {
    #       ...BlogPost
    #     }
    #   }
    # }
  }
`
